export default function linkResolver(link) {
  if (link.link_type === "Document") {
    switch (link.type) {
      case "page":
        return `/${link.uid}`;
      case "location":
        return `/location/${link.uid}`;

      default:
        return "/";
    }
  } else {
    return link.url;
  }
}
