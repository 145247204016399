import React from "react";
import { css } from "@emotion/react";
import { graphql, Link } from "gatsby";
import linkResolver from "../utils/linkResolver";
export default function OnlineShop({ data }) {
  return (
    <div className="py-10 lg:py-20">
      <div>
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2" />
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="object-cover w-full h-full filter "
                  css={css`
                    --tw-blur: blur(2px);
                  `}
                  src={data?.primary?.image?.fluid?.src}
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-blue-light/70 mix-blend-hard-light dark:bg-blue-dark/90 dark:mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <div
                  className="prose text-center max-w-none dark:prose-dark"
                  // css={css`
                  //   h1,
                  //   h2,
                  //   h3 {
                  //     color: #fff !important;
                  //   }
                  // `}
                  dangerouslySetInnerHTML={{
                    __html: data?.primary?.text?.html,
                  }}
                ></div>
                <div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
                  {data?.primary?.button_link?.link_type === "Document" ? (
                    <Link
                      className="flex items-center justify-center px-4 py-3 text-base font-medium text-black border border-transparent rounded-md shadow-sm dark:hover:textblack hover:text-white bg-orange hover:bg-blue-dark dark:hover:bg-blue-light sm:px-8"
                      to={linkResolver(data?.primary?.button_link)}
                    >
                      {data?.primary?.button_label}
                    </Link>
                  ) : (
                    <a
                      className="flex items-center justify-center px-4 py-3 text-base font-medium text-black border border-transparent rounded-md shadow-sm dark:hover:textblack hover:text-white bg-orange hover:bg-blue-dark dark:hover:bg-blue-light sm:px-8"
                      href={data?.primary?.button_link?.url}
                      target={data?.primary?.button_link?.target}
                    >
                      {data?.primary?.button_label}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment OnlineShop on PrismicPageBodyOnlineShop {
    id
    slice_type
    primary {
      button_link {
        uid
        url
        type
        link_type
        target
      }
      button_label
      image {
        fluid {
          src
        }
      }
      text {
        html
      }
    }
  }
  fragment HomepageOnlineShop on PrismicHomepageBodyOnlineShop {
    id
    slice_type
    primary {
      button_link {
        uid
        url
        type
        link_type
        target
      }
      button_label
      image {
        fluid {
          src
        }
      }
      text {
        html
      }
    }
  }
`;
